<!-- layouts/default.vue -->  
<template>
  <div id="ste">
    <Header />
    <!-- 插槽，页面内容将会插入到这里 -->
    <slot />
    <Footer />
  </div>
</template> 
<script setup>
//google统计
// onMounted(() => {
//   window.dataLayer = window.dataLayer || [];
//   function gtag() { dataLayer.push(arguments); }
//   gtag('js', new Date());
//   gtag('config', 'G-3484DXYQWN');
// })


const runtimeConfig = useRuntimeConfig();
if (!runtimeConfig.local) {
  useHead({
    script: [
      {
        src: `https://hm.baidu.com/hm.js?f567e4d1ff240d16c96ec6b5f6d2fec9`,
        async: true,
      },
      {
        src: `https://www.googletagmanager.com/gtag/js?id=G-FK2R3179KW`,
        async: true,
      },
      {
        children: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
       gtag('js', new Date());
       gtag('config', 'G-FK2R3179KW');
      `,
      },
    ],
  })
}
</script> 
      