<template>
    <div>
        <div class="x-container layui-fluid">
            <div class="layui-container">
                <div class="x-logo"><a href="/"><img alt="logo" src="/assets/img/logo_top.svg" /></a></div>
                <!-- <div class="x-search">
                    <a href="/citys/1-伦敦">
                        <img alt="search" src="/assets/img/Search.svg" />
                        <input readonly placeholder="伦敦热门院校" value="" />
                    </a>
                </div> -->

                <!-- <transition  name="fade"> -->
                <ul id="x-nav" class="x-nav layui-bg-gray" :class="tree" v-show="isShowMenu" style="padding-right:0">
                    <li class="layui-nav-item">
                        <a class="nav-menu" href="javascript:;">课程</a>
                        <dl class="layui-nav-child">
                            <dd><a href="/courses/1-一般英语课程">一般英语课程</a></dd>
                            <dd><a href="/courses/2-雅思英语课程">雅思英语课程</a></dd>
                            <dd><a href="/courses/3-零基础英语课程">零基础英语课程</a></dd>
                            <dd><a href="/courses/4-商务英语课程">商业英语课程</a></dd>
                            <dd><a href="/courses/5-口语强化课程">口语强化课程</a></dd>
                            <dd><a href="/courses/6-30+通用英语课程">30+通用英语课程</a></dd>
                        </dl>
                    </li>
                    <li class="layui-nav-item">
                        <a class="nav-menu ml-24" href="javascript:;">地区</a>
                        <dl class="layui-nav-child">
                            <dd v-for="(item, index) in refCitis?.data || []" :key="index">
                                <a :href="`/citys/${myUtils.formatSlug(item.id, item.attributes.name)}`">{{ item.attributes.name }}</a>
                            </dd>
                        </dl>
                    </li>
                    <li class="layui-nav-item">
                        <a class="nav-menu ml-24" href="javascript:;">文章</a>
                        <dl class="layui-nav-child">
                            <dd v-for="item in refBlogTypes?.data || []">
                                <a :href="`/articles/types/${myUtils.formatSlug(item.id, item.attributes.name)}`">{{ item.attributes.name }}</a>
                            </dd>
                        </dl>
                    </li>
                    <li class="layui-nav-item"><a class="nav-menu ml-24" href="/about">关于我们</a></li>
                    <li class="layui-nav-item"><a class="nav-menu" href="/signup" style="padding-right:0">立即报名</a></li>
                </ul>
                <!-- </transition> -->
                <div class="menu"><a href="javascript:" @click="toggleMenu"><img alt="menu" src="/assets/img/about_menu.svg" /></a>
                </div>
            </div>
        </div>
        <div class="blank"></div>
    </div>
</template>

<style scoped>
.blank {
    height: 88px;
}

.x-logo {
    width: 213.556px;
    height: 80px;
    flex-shrink: 0;
    position: absolute;
    left: 0;
    top: 0;
}

.x-logo img {
    height: 80px;
}

.menu img {
    height: 32px;
}

.x-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 88px;
    z-index: 1000;
    background-color: white;
    padding: 0;
}



.x-search {
    display: flex;
    width: 256px;
    height: 40px;
    padding: 0px 12px;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    position: absolute;
    left: 245px;
    top: 24px;
    border-radius: 16px;
    border: 1px solid var(--line-divider-primary, #F4F5F6);
    background: var(--fill-page-primary, #FFF);
}

.x-search input {
    border: 0;
    width: 100%;
    cursor:pointer
}
.x-search a{
    display:flex;
}
.x-nav {
    position: absolute;
    top: 0;
    right: 0;
    background-color: white !important;
}

.x-nav * {
    font-size: 16px;
}

.x-nav .layui-nav-item a {
    padding-left: 24px;
}

.layui-nav-item {
    line-height: 88px;
}



.x-nav {
    position: fixed;
    top: 88px;
    height: 100%;
}

.layui-nav.layui-nav-tree {
    width: 100%;
}

.ml-24 {
    margin-left: 0;
}

.x-search {
    display: none;
}

.menu {
    position: absolute;
    right: 32px;
    top: 28px;
    width: 32px;
    height: 32px;
    display: block;
}

.x-logo {
    left: 32px;
}

.layui-nav-item .layui-nav-child a {
    color: #787D83;
}

.nav-menu {
    color: var(--text-text-primary, #2B2F33);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 8px;
}

.layui-nav-child {
    border-radius: 16px;
}

@media screen and (min-width: 1200px) {
    /**箭头 */
    /* .layui-nav-child::before {
        content: "";
    position: absolute;
    top: -11px; 
    left: 30px; 
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 11px solid #eee;
    }

    .layui-nav-child::after {
        content: "";
    position: absolute;
    top: -10px; 
    left: 30px; 
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    } */

    .nav-menu {
        margin-left: 0;
        color: var(--text-text-primary, #2B2F33);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 88px;
    }

    .x-logo {
        left: 0;
    }

    .menu {
        display: none;
    }

    .x-search {
        display: flex;
    }

    .x-nav {
        position: absolute;
        top: 0;
        height: auto;
    }

    .layui-nav.layui-nav-tree {
        width: 200px;
    }

    .ml-24 {
        margin-left: 24px;
    }
}

/* 定义过渡的样式 */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
    /* 过渡持续时间设置为0.5秒 */
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
    {
    opacity: 0;
    /* 进入时的透明度 */
}
</style>


<script setup>
let tree = ref("");
let isShowMenu = ref(false);
onMounted(() => {

    function updateNavTreeClass() {
        if (window.innerWidth < 1200) {
            tree.value = 'layui-nav layui-nav-tree';
            isShowMenu.value = false;
        } else {
            tree.value = "layui-nav";
            isShowMenu.value = true;
        }
        document.getElementById('x-nav').querySelectorAll('li.layui-nav-itemed').forEach(function (item) {
            item.classList.remove('layui-nav-itemed');
        });
    }
    // //监听窗口大小变化事件
    window.addEventListener('resize', updateNavTreeClass);
    updateNavTreeClass();
    setTimeout(() => {
        layui.element.render({ "nav": "x-nav" });
    }, 100);
});

const toggleMenu = () => {
    isShowMenu.value = !isShowMenu.value;
};


const url_type = '/api/fw/blog-types?publicationState=preview';
console.log('blog-types:', url_type);
const { data: refBlogTypes } = await useFetch(url_type);


const url = '/api/fw/cities?publicationState=preview';
console.log('cities:', url);
const { data: refCitis } = await useFetch(url);

</script>



