<template>
    <div class="layui-fluid x-bg-black x-pt40">
        <div class="layui-container">
            <div class="layui-row">
                <div class="layui-col-xs12 layui-col-lg4 x-pb32">
                    <img alt="logo" class="logo" src="/assets/img/logo_bottom.svg" />
                </div>
                <div class="layui-col-xs12 layui-col-lg8">
                    <div class="layui-row">
                        <div class="layui-col-xs6 layui-col-lg3">
                            <p class="x-size-24 x-color-white">语言学校地区</p>
                            <p><a href="/citys/1-伦敦">伦敦</a></p>
                            <p><a href="/citys/2-剑桥">剑桥</a></p>
                            <p><a href="/citys/3-布莱顿">布莱顿</a></p>
                            <p><a href="/citys/4-曼彻斯特">曼彻斯特</a></p>
                            <p><a href="/citys/5-利物浦">利物浦</a></p>
                            <p><a href="/citys/6-伯明翰">伯明翰</a></p>
                        </div>
                        <div class="layui-col-xs6 layui-col-lg3">
                            <p class="x-size-24 x-color-white">课程介绍</p>
                            <p><a href="/courses/1-一般英语课程">一般英语课程</a></p>
                            <p><a href="/courses/2-雅思英语课程">雅思英语课程</a></p>
                            <p><a href="/courses/3-零基础英语课程">零基础英语课程</a></p>
                            <p><a href="/courses/4-商务英语课程">商务英语课程</a></p>
                            <p><a href="/courses/5-口语强化课程">口语强化课程</a></p>
                            <p><a href="/courses/6-30+通用英语课程">30+通用英语课程</a></p>
                        </div>
                        <div class="layui-col-xs6 layui-col-lg3">
                            <p class="x-size-24 x-color-white">文章</p>
                            <p><a href="/articles/4-英国游学介绍指南">英国游学介绍指南</a></p>
                            <p><a href="/articles/5-英国语言学校介绍">英国语言学校介绍</a></p>
                            <p><a href="/articles/7-英国游学介绍-签证篇">英国游学介绍 -- 签证篇</a></p>
                            <p><a href="/articles/6-英国游学介绍-住宿篇">英国游学介绍 -- 住宿篇</a></p>
                            <p><a href="/articles/8-英语水平测验">英语水平测验</a></p>
                        </div>
                        <div class="layui-col-xs6 layui-col-lg3">
                            <p class="x-size-24 x-color-white">关于</p>
                            <p><a href="/about">关于小鸥</a></p>
                            <p><a href="/signup">立即报名</a></p>
                            <p><a href="/signup">联系我们</a></p>
                            <p><a href="/signup">商业合作</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="x-color-white x-pt32 x-size-14 x-text-center"><a href="http://beian.miit.gov.cn/" target="_blank">工业和信息化部备案管理系统网站 京ICP备2023017186号-6</a></div>
            <div class="x-color-white x-pb32 x-size-16 x-text-center">Copyrights © Xiaoou {{ year }} all right reserved.</div>

        </div>
    </div>
</template>
<style scoped>
p {
    margin: 12px 0;
}

a {
    color: #fff;
    font-size: 14px;
}

.logo {
    height: 80px;
}
</style>

<script setup>
const year = ref(new Date().getFullYear());

//https://blog.csdn.net/qq_29483485/article/details/107162522
//解决微信调整字体大小导致Html5页面混乱
//android

onMounted(()=> {
    (function () {
        if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
            handleFontSize();
        } else {
            document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
        }
        function handleFontSize() {
            // 设置网页字体为默认大小
            WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize': 0 });
            // 重写设置网页字体大小的事件
            WeixinJSBridge.on('menu:setfont', function () {
                WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize': 0 });
            });
        }
    })();
});

</script>